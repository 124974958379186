<template>
      <div class="companyVehicleCreate">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'companyVehicle', params: {} }">
          Company Vehicle
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'companyVehicleCreate', params: {} }">
            Create Company Vehicle
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Create Company Vehicle</v-card-title>
          <v-card-text>
            <companyVehicle-form></companyVehicle-form>
          </v-card-text>
        </v-card>
      </div>
    </template>
    <script>
    import CompanyVehicleForm from './CompanyVehicleForm.vue';

    export default {
      name: 'companyVehicle',
      components: {
        CompanyVehicleForm
      },
      created() {
        this.$emit("showParent", false);
      }
    }
    </script>
    